import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "sessionStorage",
  storage: window.sessionStorage, // persistent on session storage
  modules: ["current"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    jwtDecoded: null,
    current: null,
    transactionPerformed: false,
    lockedMethods: [],
    awaitingMethods: [],
  },

  getters: {
    jwtDecoded: ({ jwtDecoded }) => jwtDecoded,
    current: ({ current }) => current,
    transactionPerformed: ({ transactionPerformed }) => transactionPerformed,
    lockedMethods: ({ lockedMethods }) => lockedMethods,
    awaitingMethods: ({ awaitingMethods }) => awaitingMethods,
  },

  mutations: {
    SET_JWT_DECODED(state, payload) {
      state.jwtDecoded = payload;
    },

    SET_CURRENT(state, payload) {
      state.current = payload;
    },

    SET_TRANSACTION_PERFOMED(state, payload) {
      state.transactionPerformed = payload;
    },

    SET_LOCKED_METHODS(state, payload) {
      state.lockedMethods = payload;
    },

    SET_AWAITING_METHODS(state, payload) {
      state.awaitingMethods = payload;
    },
  },

  actions: {
    setJwtDecoded({ commit }, payload) {
      commit("SET_JWT_DECODED", payload);
    },

    setCurrent({ commit }, payload) {
      commit("SET_CURRENT", payload);
    },

    setTransactionPerformed({ commit }, payload) {
      commit("SET_TRANSACTION_PERFOMED", payload);
    },

    setLockedMethods({ commit }, payload) {
      commit("SET_LOCKED_METHODS", payload);
    },

    setAwaitingMethods({ commit }, payload) {
      commit("SET_AWAITING_METHODS", payload);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
