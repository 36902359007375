import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#ff5500",
        secondary: "#24d9ed",
        accent: "#f8f9fa",
        background: "#f0f0f5",
        foreground: "#ffffff",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        primary_text: "#393940",
        secondary_text: "#8C8C8C",
      },
      dark: {
        primary: "#ff5500",
        secondary: "#24d9ed",
        accent: "#2D2D33",
        background: "#1c1c1f",
        foreground: "#222226",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        primary_text: "#FFFFFF",
        secondary_text: "#B9B9B9",
      },
    },
  },
  lang: {
    locales: { pt },
    current: "pt",
  },
});
