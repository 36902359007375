<template>
  <v-app>
    <v-main class="background">
      <router-view />
    </v-main>

    <v-btn absolute bottom right fixed icon @click="changeTheme()">
      <v-icon v-if="$vuetify.theme.dark">mdi-moon-waning-crescent</v-icon>
      <v-icon v-else> mdi-white-balance-sunny </v-icon>
    </v-btn>

    <AlertBar />
  </v-app>
</template>

<script>
import AlertBar from "@/components/AlertBar";

export default {
  data() {
    return {};
  },

  components: {
    AlertBar,
  },

  beforeMount() {
    this.startTheme();
  },

  watch: {
    ["$route"](to, from) {
      if (Object.keys(from.query).length > 0) {
        // to prevent an infinite loop
        this.$router.replace({ query: from.query }).catch(() => {
          //ignore
        });
      }
    },
  },

  methods: {
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark", this.$vuetify.theme.dark);
    },

    startTheme() {
      const dark = localStorage.getItem("dark");
      if (dark) this.$vuetify.theme.dark = dark == "true" || false;
    },
  },
};
</script>

<style lang="scss">
</style>