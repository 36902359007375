import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/auth",
    name: "Auth",
    redirect: "/checkout",
    component: () => import("@/views/auth/Auth"),
  },
  {
    path: "/checkout",
    component: () => import("@/views/checkout/Checkout"),
    children: [
      // select payment step
      {
        path: "",
        name: "Checkout",
        component: () => import("@/views/checkout/main/Main"),
      },
      // payment checkout step
      {
        path: "payment",
        component: () => import("@/views/checkout/payment/Payment"),
        redirect: "/checkout", // prevent access without method
        children: [
          {
            path: "boleto",
            name: "Payment Boleto",
            component: () =>
              import("@/views/checkout/payment/children/Boleto"),
          },
          {
            path: "card",
            name: "Payment Card",
            component: () => import("@/views/checkout/payment/children/Card"),
          },
          {
            path: "picpay",
            name: "Payment PicPay",
            component: () => import("@/views/checkout/payment/children/PicPay"),
          },
          {
            path: "pix",
            name: "Payment Pix",
            component: () => import("@/views/checkout/payment/children/Pix"),
          },
          {
            path: "transfer",
            name: "Payment Transfer",
            component: () =>
              import("@/views/checkout/payment/children/Transfer"),
          },
        ],
      },
      // review step
      {
        path: "summary/:newTransaction?",
        name: "Summary",
        component: () => import("@/views/checkout/summary/Summary"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/checkout",
  },
];

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
