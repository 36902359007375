<template>
  <v-overlay style="background-color: #000000bf">
      <v-progress-circular :size="64"  color="primary" indeterminate>
      <v-progress-circular :size="44" width="6" color="secondary" indeterminate />
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {};
</script>

<style>
</style>